import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ErrorsService } from '../errors/errors.service';
import { environment as env } from '../../../environments/environment';

import { Record } from '../../models/record';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class CalendarNavService {
  public calendarNav$: BehaviorSubject<calendarNavObj> = new BehaviorSubject({
    navPatientId: null,
    navPatientName: null,
    navRecord: null,
    navRecordId: null,
    navUserId: null,
    navRecordtypeId: null,
    navWaitinglistitemId: null
  });

  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService
  ) { }

}


export interface calendarNavObj {
  navPatientId: number,
  navPatientName: string,
  navRecordId: number,
  navRecord: Record,
  navUserId: number,
  navRecordtypeId: number,
  navWaitinglistitemId: number
}