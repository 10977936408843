import { Injectable } from '@angular/core';
import { lang, locales } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor() { }

  public getLanguage(defaultlang?: string, altlang?: string) {
    let lang = 'nl';
    const availableLangs = ["nl","fr","es","de","en"];
    //https://www.localeplanet.com/api/codelist.json
    const esLangs = ["es","es-419","es-AR","es-BO","es-BR","es-BZ","es-CL","es-CO","es-CR","es-CU","es-DO","es-EA","es-EC","es-ES","es-GQ","es-GT","es-HN","es-IC","es-MX","es-NI","es-PA","es-PE","es-PH","es-PR","es-PY","es-SV","es-US","es-UY","es-VE","gl-ES","eu-ES","ca-ES","ast-ES"];
    const frLangs = ["fr","fr-BE","fr-BF","fr-BI","fr-BJ","fr-BL","fr-CA","fr-CD","fr-CF","fr-CG","fr-CH","fr-CI","fr-CM","fr-DJ","fr-DZ","fr-FR","fr-GA","fr-GF","fr-GN","fr-GP","fr-GQ","fr-HT","fr-KM","fr-LU","fr-MA","fr-MC","fr-MF","fr-MG","fr-ML","fr-MQ","fr-MR","fr-MU","fr-NC","fr-NE","fr-PF","fr-PM","fr-RE","fr-RW","fr-SC","fr-SN","fr-SY","fr-TD","fr-TG","fr-TN","fr-VU","fr-WF","fr-YT","ca-FR","br-FR"];
    const nlLangs = ["nl","nl-AW","nl-BE","nl-BQ","nl-CW","nl-NL","nl-SR","nl-SX","nds-NL","eu-NL","en-NL"];
    const deLangs = ["de","de-AT","de-BE","de-CH","de-DE","de-IT","de-LI","de-LU"];
    const enLangs = ["en","en-001","en-150","en-AG","en-AI","en-AS","en-AT","en-AU","en-BB","en-BE","en-BI","en-BM","en-BS","en-BW","en-BZ","en-CA","en-CC","en-CH","en-CK","en-CM","en-CX","en-CY","en-DE","en-DG","en-DK","en-DM","en-ER","en-FI","en-FJ","en-FK","en-FM","en-GB","en-GD","en-GG","en-GH","en-GI","en-GM","en-GU","en-GY","en-HK","en-IE","en-IL","en-IM","en-IN","en-IO","en-JE","en-JM","en-KE","en-KI","en-KN","en-KY","en-LC","en-LR","en-LS","en-MG","en-MH","en-MO","en-MP","en-MS","en-MT","en-MU","en-MW","en-MY","en-NA","en-NF","en-NG","en-NL","en-NR","en-NU","en-NZ","en-PG","en-PH","en-PK","en-PN","en-PR","en-PW","en-RW","en-SB","en-SC","en-SD","en-SE","en-SG","en-SH","en-SI","en-SL","en-SS","en-SX","en-SZ","en-TC","en-TK","en-TO","en-TT","en-TV","en-TZ","en-UG","en-UM","en-US","en-US-POSIX","en-VC","en-VG","en-VI","en-VU","en-WS","en-ZA","en-ZM","en-ZW"];
    if ((defaultlang && altlang) || (!defaultlang && !altlang)) {
      lang = navigator.language;
      if (frLangs.includes(lang)) lang = 'fr';
      else if (esLangs.includes(lang)) lang = 'es';
      else if (nlLangs.includes(lang)) lang = 'nl';
      else if (deLangs.includes(lang)) lang = 'de';
      else if (enLangs.includes(lang)) lang = 'en';
      else lang = 'nl';
    } else if (defaultlang) {
      lang = defaultlang;
    }
    if (defaultlang && !altlang) {
      localStorage.setItem('language', lang);
    }
    else {
      if (localStorage.getItem('language') && availableLangs.includes(localStorage.getItem('language'))) {
        lang = localStorage.getItem('language');
      } else {
        localStorage.setItem('language', lang);
      }
    }
    return lang;
  }

  public setLanguage(lang: string) {
    localStorage.setItem('language', lang);
  }

  public getBrowserCountry() {
    let lang = navigator.language;
    let country = 'BE';
    if (lang.indexOf('-FR')>-1) country = 'FR';
    if (lang.indexOf('-NL')>-1) country = 'NL';
    return country;
  }

  public mapNameToLanguage(objects: any, altlang: string) {
    if (this.getLanguage()==altlang) {
      objects.map(obj => obj.name = (obj.nameAltlang ? obj.nameAltlang : obj.name));
      objects.map(obj => obj.parameters ? (obj.parameters = (obj.parametersAltlang ? obj.parametersAltlang : obj.parameters)) : obj);
    }
    return objects;
  }
}