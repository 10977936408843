import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Recordtype } from '../../models/Recordtype';

import { ErrorsService } from '../errors/errors.service';
import { environment as env } from '../../../environments/environment';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class RecordtypesService {
  public recordtypes$: BehaviorSubject<Recordtype[]> = new BehaviorSubject([]);

  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService,
  ) {}

  public getRecordtypes(): Observable<RecordtypesResponse> {
    const url = env.base_url + '/recordtypes';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };

    const recordtypesFetched$ = this.http.get<RecordtypesResponse>(url, options)
      .pipe(tap(
        (response) => {
          this.recordtypes$.next(response.data.recordtypes);
        },
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return recordtypesFetched$;
  } 

  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new HttpHeaders(headers);
  }
}

export interface RecordtypesResponse {
  status,
  data: {
    recordtypes: Recordtype[];
    askAnamnese: number;
  };
}
