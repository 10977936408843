<div class="modal-header">
  <h4 class="modal-title"><span translate>user.discover_modal.title</span></h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p><span translate>user.discover_modal.text</span></p>
</div>
<div class="modal-footer" ngbAutofocus>
  <button class="btn btn-outline-secondary" type="button" (click)="closeModal()"><span translate>user.discover_modal.later</span></button>
  <button class="btn btn-primary btn-block" type="submit" (click)="requestDemo()">
    <span *ngIf="!requestingDemo"><span translate>user.discover_modal.request</span></span>
    <span *ngIf="requestingDemo"><i class="fa fa-circle-o-notch fa-spinner fa-spin"></i></span>
  </button>
</div>