import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { InputMaskModule } from 'racoon-mask-raw';
import { NgSelect2Module } from 'ng-select2';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutosizeModule  } from 'ngx-autosize';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader  } from '@ngx-translate/http-loader';

import { NavbarComponent } from "./navbar/navbar.component";
import { FormComponent } from "./form/form.component";
import { FormItemComponent } from "./form-item/form-item.component";
import { RevenueComponent } from "./revenue/revenue.component";
import { SalesrecordModalComponent } from "./revenue/salesrecord-modal/salesrecord-modal.component";
import { InvoicesComponent } from "./invoices/invoices.component";
import { AddCreditinvoiceModalComponent } from "./invoices/add-creditinvoice-modal/add-creditinvoice-modal.component";
import { RecordlogsModalComponent } from "./recordlogs/recordlogs-modal.component";
import { NewAnamneseModalComponent } from "./new-anamnese-modal/new-anamnese-modal.component";
import { RecordsOverviewComponent } from "./records-overview/records-overview.component";
import { RecordsOverviewService } from "./records-overview/records-overview.service";
import { EritConfigService } from "./erit-config/erit-config.service";
import { EmailDetailsModalComponent } from "./email-details-modal/email-details-modal.component";

import { LocalizedDatePipe } from '../pipes/localized-date.pipe';

import { environment as env } from '../../environments/environment';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        AutosizeModule,
        InputMaskModule,
        NgSelect2Module,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: false
        })
    ],
    declarations: [
        NavbarComponent,
        FormComponent,
        FormItemComponent,
        RevenueComponent,
        InvoicesComponent,
        SalesrecordModalComponent,
        AddCreditinvoiceModalComponent,
        RecordlogsModalComponent,
        NewAnamneseModalComponent,
        RecordsOverviewComponent,
        EmailDetailsModalComponent,
        LocalizedDatePipe,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NavbarComponent,
        FormComponent,
        FormItemComponent,
        NgbModule,
        RevenueComponent,
        InvoicesComponent,
        SalesrecordModalComponent,
        RecordlogsModalComponent,
        AddCreditinvoiceModalComponent,
        NewAnamneseModalComponent,
        RecordsOverviewComponent,
        EmailDetailsModalComponent,
        AutosizeModule,
        TranslateModule,
        InputMaskModule,
        NgSelect2Module,
        LocalizedDatePipe,
    ],
    providers: [
        RecordsOverviewService,
        EritConfigService,
    ]
})
export class SharedModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, env.assets_url+'/i18n/', '.json');
}