import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (date) {
      let day = date.day<10 ? '0'+date.day : ''+date.day;
      let month_ = date.month;
      let month = month_<10 ? '0'+month_ : ''+month_;
      return day + this.DELIMITER + month + this.DELIMITER + date.year;
    } else {
      return '';
    }
  }
}