import { Component, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { UserService } from '../../shared/user/user.service';
import { ErrorsService } from '../../shared/errors/errors.service';

import { User } from '../../models/User';
import { Subscription } from 'rxjs';

import { environment as env } from '../../../environments/environment';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {
    public authUser: User;
    public showTrialAlert: boolean = false;
    public assetsUrl: string;
    public lastActivePatientId: number;
    public lastActiveRecordId: number;

    public logoutSubscription: Subscription;
    public userSubscription: Subscription;
    public urlSubscription: Subscription;
    public lastActivePatientIdSubscription: Subscription;
    public lastActiveRecordIdSubscription: Subscription;

    @Output() onOpenDiscoverModalClicked = new EventEmitter();

    constructor(
        public router: Router,
        public userService: UserService,
        public errorsService: ErrorsService,
    ) {}

    ngOnInit() {
        this.assetsUrl = env.assets_url;
        
        this.userSubscription = this.userService.user$.subscribe(u => {
            this.authUser = u;
        });

        this.lastActivePatientIdSubscription = this.userService.lastActivePatientId$.subscribe(lastActivePatientId => {
            this.lastActivePatientId = lastActivePatientId;
        });
        this.lastActiveRecordIdSubscription = this.userService.lastActiveRecordId$.subscribe(lastActiveRecordId => {
            this.lastActiveRecordId = lastActiveRecordId;
        });

        this.setShowTrialAlert(this.router.url);
        this.urlSubscription = this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) this.setShowTrialAlert(e.url);
        });

        let url = this.router.url;
    }

    public setShowTrialAlert(url: string) {
        this.showTrialAlert = (url.indexOf('/app/calendar')>-1);  
    }

    public logout() {
        this.logoutSubscription = this.userService.logout().subscribe(
            (response) => {                
                this.router.navigate(['/auth/login']);
            },
            (error) => {
                this.errorsService.handleErrorMessage(error);
            }
        );
    }

    public discover() {
        this.onOpenDiscoverModalClicked.emit();
    }

    public goToUserAgreement() {
        window.open('https://www.esculon.eu/user-agreement', '_blank');
    }

    ngOnDestroy() {
        if (this.userSubscription) this.userSubscription.unsubscribe();
        if (this.urlSubscription) this.urlSubscription.unsubscribe();
        if (this.logoutSubscription) this.logoutSubscription.unsubscribe();
        if (this.lastActivePatientIdSubscription) this.lastActivePatientIdSubscription.unsubscribe();
        if (this.lastActiveRecordIdSubscription) this.lastActiveRecordIdSubscription.unsubscribe();
    }
}
