import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader  } from '@ngx-translate/http-loader';
import { RouteReuseStrategy } from '@angular/router';

import { CustomReuseStrategy } from './shared/routing/routing';
import { CustomDateParserFormatter } from './shared/custom-date-parser-formatter/custom-date-parser-formatter';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from './app.component';
import { AuthContainerComponent } from './containers/auth-container/auth-container.component';
import { SetupContainerComponent } from './containers/setup-container/setup-container.component';
import { AppContainerComponent } from './containers/app-container/app-container.component';
import { DiscoverModalComponent } from './containers/app-container/discover-modal/discover-modal.component';
import { ProfileComponent } from './pages/app/profile/profile.component';

import { AuthGuard } from './shared/auth-guard/auth-guard.service';
import { SetupOngoingGuard } from './shared/setup-ongoing-guard/setup-ongoing-guard.service';
import { SetupFinishedGuard } from './shared/setup-finished-guard/setup-finished-guard.service';
import { SettingsGuard } from './shared/settings-guard/settings-guard.service';
import { CalendarGuard } from './shared/calendar-guard/calendar-guard.service';
import { ErrorsService } from './shared/errors/errors.service';
import { FieldService } from "./shared/field/field.service";
import { UserService } from './shared/user/user.service';
import { TherapistsService } from "./shared/therapists/therapists.service";
import { RecordtypesService } from "./shared/recordtypes/recordtypes.service";
import { PaymentmethodsService } from "./shared/paymentmethods/paymentmethods.service";
import { SalesitemsService } from "./shared/salesitems/salesitems.service";
import { RevenueService } from "./shared/revenue/revenue.service";
import { InvoicesService } from "./shared/invoices/invoices.service";
import { ProfileService } from "./pages/app/profile/profile.service";
import { DiscoverModalService } from "./containers/app-container/discover-modal/discover-modal.service";
import { FindPatientsService } from "./shared/find-patients/find-patients.service";
import { NewAnamneseModalService } from "./shared/new-anamnese-modal/new-anamnese-modal.service";
import { RecordlogsService } from "./shared/recordlogs/recordlogs.service";
import { LocaleService } from "./shared/locale/locale.service";
import { CalendarNavService } from "./shared/calendar-nav/calendar-nav.service";

import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { environment as env } from '../environments/environment';

import * as $ from 'jquery';
import 'jcanvas';

import localeNl from '@angular/common/locales/nl';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
registerLocaleData(localeNl, "nl");
registerLocaleData(localeFr, "fr");
registerLocaleData(localeEs, "es");
registerLocaleData(localeDe, "de");
registerLocaleData(localeEn, "en");


@NgModule({
    declarations: [
        AppComponent,
        AuthContainerComponent,
        SetupContainerComponent,
        AppContainerComponent,
        DiscoverModalComponent,
        ProfileComponent,
    ],
    providers: [
        AuthGuard,
        SetupOngoingGuard,
        SetupFinishedGuard,
        SettingsGuard,
        CalendarGuard,
        ErrorsService,
        FieldService,
        UserService,
        TherapistsService,
        RecordtypesService,
        PaymentmethodsService,
        SalesitemsService,
        RevenueService,
        InvoicesService,
        ProfileService,
        DiscoverModalService,
        FindPatientsService,
        NewAnamneseModalService,
        RecordlogsService,
        CalendarNavService,
        NgbActiveModal,
        {
            provide: LOCALE_ID,
            useFactory: (localeService: LocaleService) => {
                return localeService.getLanguage();
            },
            deps: [LocaleService]
        },
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
        // { provide: ErrorHandler, useClass: RollbarErrorHandler },
        // { provide: RollbarService, useFactory: rollbarFactory }
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: false
        }),
        AppRoutingModule,
        SharedModule,
        NgbModule,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, env.assets_url+'/i18n/', '.json?v=' + Date.now());
}